<template>
  <div class="loginPage" :class="{ loadWhite: isShowLoading }">
    <div
      class="portalLogin"
      :style="{ backgroundImage: backgroundImage }"
      v-show="!isShowLoading"
    >
      <HeadInfo :is-login="true" />
      <div class="login-wrap" v-show="isOpen">
        <div class="login-img wapHide">
          <img
            src="../../assets/img/login-icon.png"
            width="400"
            alt="login icon"
          />
        </div>
        <div class="login-form">
          <span v-html="loginDesc" />
          <div class="login-nav">
            <b-nav>
              <b-nav-item
                :active="isNormalLogin"
                v-if="isNormalLoginConfig"
                @click="fnIsNormalLogin"
              >
                {{ $g("navNormalLogin") }}
              </b-nav-item>
              <b-nav-item
                :active="isAADLogin"
                v-show="isAADLoginConfig"
                @click="fnIsAADLogin"
              >
                {{ $g("navAADLogin") }}
              </b-nav-item>
            </b-nav>
          </div>
          <k-alert-msg ref="lblMsg" id="lblMsg" />
          <div v-if="isNormalLogin" class="login-main">
            <KValidationObserver ref="observer">
              <k-form @submit.prevent="saveForm">
                <k-form-group
                  label-suffix=""
                  label-class="require-mark"
                  label-for="userName"
                >
                  <KValidationProvider
                    :rules="{ required: true, max: 256 }"
                    v-slot="{ errors }"
                    slim
                    name="userName"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="userName"
                      v-model="form.username"
                      size="lg"
                      placeholder="username"
                      :aria-label="$g('userName')"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-suffix=""
                  label-class="require-mark"
                  label-for="password"
                >
                  <KValidationProvider
                    :rules="{ required: true, max: 256 }"
                    v-slot="{ errors }"
                    slim
                    name="password"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="password"
                      type="password"
                      v-model="form.passowrd"
                      size="lg"
                      placeholder="password"
                      :aria-label="$g('password')"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  label-suffix=""
                  label-class="require-mark"
                  label-for="googleAuthenticatorCode"
                  v-if="showGoogleAuth"
                >
                  <KValidationProvider
                    :rules="{ required: true, max: 256 }"
                    v-slot="{ errors }"
                    slim
                    name="googleAuthenticatorCode"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="googleAuthenticatorCode"
                      v-model="form.googleAuthenticatorCode"
                      size="lg"
                      placeholder="googleAuthenticatorCode"
                      :aria-label="$g('userName')"
                    />
                  </KValidationProvider>
                </k-form-group>
                <div class="alert alert-warning" v-if="isManMachine">
                  {{ $g("validate.required") }}
                </div>
                <ReCaptcha
                  ref="reCaptcha"
                  @validate="validate"
                  @expired="resetCaptcha"
                  style="margin-bottom: 25px"
                />
                <div class="d-lg-flex justify-content-lg-between">
                  <div class="mb-3">
                    <k-button type="submit" variant="primary"> login </k-button>
                  </div>
                  <div>
                    <div class="mb-2">
                      <k-link :href="sendCodeUri">
                        {{ $g("forgetPassword") }}
                      </k-link>
                    </div>
                    <div>
                      <k-link :href="forgetUsernameUri">
                        {{ $g("forgetUsername") }}
                      </k-link>
                    </div>
                  </div>
                </div>
              </k-form>
            </KValidationObserver>
          </div>
          <div v-if="isAADLogin" class="login-AAD">
            <k-link :href="AADUri" class="btn btn-primary">
              {{ $g("loginAAD") }}
            </k-link>
          </div>
        </div>
      </div>
      <div class="messageParty" v-show="!isOpen">
        <span v-html="portalOffMessage" />
      </div>
      <FooterInfo />
    </div>

    <div v-show="isShowLoading">
      <div class="first-loading-wrp">
        <div class="loading-wrp">
          <span class="dot dot-spin"><em /><em /><em /><em /></span>
        </div>
        <h1>{{ $g("loading") }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { getAADOauth2Url, AADAuthorize } from "@/api/aad.js";
import { login, setUserType } from "@/api/user";
import { storeage, ReCaptcha, repResult, logout } from "fe-infrastractures";
import { portalSettingMixin } from "@/utils/mixin/portalSettingMixin";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import { loginType } from "../../utils/enums/loginType.js";
import {
  getWorkerPortalPage,
  PageType,
  getSysGeneralInfo,
  getIsUrlAccessProhibit,
} from "@/api/app";
import { globalEnv, yesNo, CheckLogout} from "fe-infrastractures";

CheckLogout("worker-portal/home");

export default {
  mixins: [portalSettingMixin],
  data() {
    return {
      isShowLoading: false,
      loginDesc: "",
      sendCodeUri: "/send-code",
      forgetUsernameUri: "/forget-username",
      backgroundImage: "",
      isValidateRecaptcha: false,
      AADUri: "",
      code: "",
      isManMachine: false,
      useAAD: true,
      form: {
        username: null,
        passowrd: null,
        googleAuthenticatorCode: null,
        isRefresh: false,
      },
      isAADLogin: false,
      isNormalLogin: false,
      isAADLoginConfig: false,
      isNormalLoginConfig: false,
      isOpen: true,
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
      needGoogleAuth: yesNo.no,
    };
  },
  computed: {
    showGoogleAuth() {
      return this.needGoogleAuth == yesNo.yes;
    },
  },
  components: { ReCaptcha, HeadInfo, FooterInfo },
  methods: {
    clearInput() {
      this.username = null;
      this.password = null;
    },
    doDispatch(type, val) {
      return this.$store.dispatch(type, val);
    },
    onLogin() {
      CheckLogout("worker-portal/home");
      login(
        this.form.username,
        this.form.passowrd,
        this.form.googleAuthenticatorCode
      )
        .then((res) => {
          if (res.data.isPasswordExpired) {
            this.$router.push({
              name: "validate-reset-password",
              query: {
                code: res.data.resetPasswordCode,
                username: this.form.username,
              },
            });
          } else {
            const tokenInfo = {
              token: res.data.token,
              tokenKey: res.data.refreshToken,
            };
            this.doDispatch("user/saveToken", tokenInfo).then((m) => {
              this.doDispatch("user/getUser").then((ref) => {
                setUserType(ref.userType);
                this.$router.push({ name: "home" });
              });
            });
          }
        })
        .catch((err) => {
          this.$refs.lblMsg.message(repResult.faild, err.message);
          this.$refs.reCaptcha.reset();
        });
    },
    authorize() {
      AADAuthorize({ code: this.code }).then((res) => {
        window.aadlock = false;
        if (!res.data.isValidated) {
          this.$router.push({
            name: "aad-error",
            query: {
              errMsg: res.data.errorMsg,
            },
          });
          return;
        }
        const tokenInfo = {
          token: res.data.token,
          tokenKey: res.data.refreshToken,
        };
        this.$store
          .dispatch("user/saveToken", tokenInfo)
          .then((m) => {
            // if (res.data.isUserExist) {
            //   if (res.data.hasPermission) {
            this.$store.dispatch("user/getUser").then((ref) => {
              setUserType(ref.userType);
              this.$router.push({ name: "home" });
            });
          })
          .catch((err) => {
            window.aadlock = false;
            this.isShowLoading = false;
            console.log(err);
            this.$refs.lblMsg.message(repResult.faild, err.message);
          });
      });
    },
    async saveForm() {
      let isValid = await this.$refs.observer.validate();
      console.log(globalEnv);
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      if (isValid) {
        this.onLogin();
      }
    },
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    fnIsNormalLogin() {
      this.isNormalLogin = true;
      this.isAADLogin = false;
    },
    fnIsAADLogin() {
      this.isNormalLogin = false;
      this.isAADLogin = true;
    },
    async getLoginDesc() {
      let data = await getWorkerPortalPage(PageType.login);
      this.loginDesc = data.data.description;
      if (data.data.picture) {
        this.backgroundImage = `url(${data.data.picture})`;
      }
      return data;
    },
    loginConfigHandle(loginSetting) {
      if (loginSetting.data.loginMethod.length <= 0) {
        this.isAADLogin = false;
        this.isNormalLogin = false;
        this.isAADLoginConfig = false;
        this.isNormalLoginConfig = false;
        return;
      } else {
        let info = storeage.getSystemGeneralInfo();
        let lonmloginSettingg_PollchiefAccount =
          loginSetting.data.loginMethod.find((c) => c.value == 1);
        let lonmloginSettingg_AAD = loginSetting.data.loginMethod.find(
          (c) => c.value == 2
        );

        let loginTypeCofig = info.loginType;

        this.isAADLoginConfig =
          (loginType.AAD & loginTypeCofig) === loginType.AAD &&
          lonmloginSettingg_AAD?.relateContent == 2;
        this.isNormalLoginConfig =
          (loginType.internalAuth & loginTypeCofig) ===
            loginType.internalAuth &&
          lonmloginSettingg_PollchiefAccount?.relateContent == 2;
        if (this.isNormalLoginConfig) {
          this.isNormalLogin = true;
        } else if (this.isAADLoginConfig) {
          this.isAADLogin = true;
        }
        this.needGoogleAuth = info.needGoogleAuth;
      }
    },
    loginConfg(loginSetting) {
      this.isNormalLogin = false;
      this.isAADLogin = false;
      getSysGeneralInfo().then((resp) => {
        if (resp.data) {
          storeage.setSystemGeneralInfo(resp.data);
        }
        this.loginConfigHandle(loginSetting);
      });
    },
  },
  async created() {
    if (this.useAAD) {
      this.code = this.$route.query.code;
      if (this.code && !window.aadlock) {
        window.aadlock = true;
        this.isShowLoading = true;
        this.authorize();
      }
    }
    let loginSetting = await this.getLoginDesc();
    this.clearInput();
    this.loginConfg(loginSetting);
    let msg = storeage.timeGet("tokenOut");
    if ((this.$route.params && this.$route.params.msg) || msg) {
      logout();
      this.$refs.lblMsg.message(repResult.faild, this.$route.params.msg || msg);
    }
    if (this.useAAD) {
      getAADOauth2Url().then((response) => {
        this.AADUri = response.data;
      });
    }
  },
  mounted() {},
  async beforeRouteEnter(to, from, next) {
    let data = await getWorkerPortalPage(13);
    if (data.data.publicPortal == 2) {
      next({
        name: "urlAccessProhibit",
        query: {
          publicPortal: data.data.publicPortal,
        },
      });
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.loadWhite {
  background-color: #fff;
}

.first-loading-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  min-height: 90vh;
}

.first-loading-wrp > h1 {
  font-size: 30px;
  font-weight: bolder;
}

.first-loading-wrp .loading-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 98px;
}

.dot {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 64px;
  height: 64px;
  font-size: 64px;
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}

.dot em {
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #1890ff;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  animation: antSpinMove 1s infinite linear alternate;
}

.dot em:nth-child(1) {
  top: 0;
  left: 0;
}

.dot em:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.dot em:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.dot em:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
</style>
